








































import DynamicQuestionMixin from '../../mixins/dynamic-question';
import StembleLatex from '@/tasks/components/StembleLatex.vue';

export default {
  name: 'uOttawa1321Prelab4Q6',
  components: {
    StembleLatex,
  },
  mixins: [DynamicQuestionMixin()],
  data() {
    const browserLanguage = navigator.language.split('-')[0]; // Extract the language code
    return {
      inputs: {
        studentAnswer: null,
        language: browserLanguage === 'fr' ? 'fr' : 'en', // Set the initial language based on browser preference
      },
      options: [
        {
          text: 'Oxidized',
          value: 'oxidized',
        },
        {
          text: 'Reduced',
          value: 'reduced',
        },
        {
          text: 'Neither',
          value: 'neither',
        },
      ],
      // éè
      optionsFr: [
        {
          text: 'Oxydé',
          value: 'oxidized',
        },
        {
          text: 'Réduit',
          value: 'reduced',
        },
        {
          text: "Ni l'un ni l'autre",
          value: 'neither',
        },
      ],
    };
  },
};
